import { render, staticRenderFns } from "./attach-nft-tab.vue?vue&type=template&id=b48184bc&scoped=true&"
import script from "./attach-nft-tab.vue?vue&type=script&lang=ts&"
export * from "./attach-nft-tab.vue?vue&type=script&lang=ts&"
import style0 from "./attach-nft-tab.vue?vue&type=style&index=0&id=b48184bc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b48184bc",
  null
  
)

export default component.exports






























import { NftGalleryModel } from '@/models/nft-gallery-model'
import { popoStore } from '@/stores/popo-store'
import { some } from 'lodash'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'attach-nft-card': () => import('@/modules/popo/components/attachs/attach-nft-card.vue'),
  },
})
export default class extends Vue {
  @Prop({ default: () => [] }) nfts
  @Prop({ default: () => [] }) attachedNfts
  @Prop({ default: () => '' }) type
  popoStore = popoStore
  selectedNft = null as any

  clearSelectedNft() {
    this.selectedNft = null
  }

  get isExistAttachedNft() {
    return some(this.nfts, (item) => item.forceAttach)
  }

  get itemPerRow() {
    return this.$vuetify.breakpoint.lgAndUp ? 4 : 3
  }
  get totalRows() {
    return Math.ceil(this.nfts.length / this.itemPerRow)
  }

  changeAttach(isChecked, nft) {
    this.selectedNft = isChecked ? nft : null
    this.popoStore.changeAttachNft(isChecked, { ...nft })
  }
}
